var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('Patcher',{attrs:{"itemValue":_vm.itemData.isActive,"itemName":"isActive","route":_vm.patchRoute,"inputElement":"switch","inputType":"","previewValue":_vm.itemData.isActive,"itemLabel":"Aktiv"},on:{"emit":function($event){_vm.itemData.isActive = $event.value}}}),_c('Patcher',{attrs:{"itemValue":_vm.itemData.sendDay,"itemName":"sendDay","itemLabel":"Versand Tag","hideLabel":false,"route":_vm.patchRoute,"inputElement":"select","inputType":"number","selectOptions":[
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],"itemValueKey":"","itemTextKey":"","previewValue":_vm.itemData.sendDay},on:{"emit":function($event){_vm.itemData.sendDay = $event.value}}}),_c('Patcher',{attrs:{"itemValue":_vm.itemData.sendMonth,"itemName":"sendMonth","itemLabel":"Versand Monat","hideLabel":false,"route":_vm.patchRoute,"inputElement":"select","inputType":"number","selectOptions":[
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
    ],"itemValueKey":"","itemTextKey":"","previewValue":_vm.itemData.sendMonth},on:{"emit":function($event){_vm.itemData.sendMonth = $event.value}}}),_c('Patcher',{attrs:{"itemValue":_vm.itemData.sendTime,"itemName":"sendTime","itemLabel":"Versand Uhrzeit","hideLabel":false,"route":_vm.patchRoute,"inputElement":"input","inputType":"time","selectOptions":"","itemValueKey":"","itemTextKey":"","previewValue":_vm.itemData.sendTime},on:{"emit":function($event){_vm.itemData.sendTime = $event.value}}}),_c('Patcher',{attrs:{"itemValue":_vm.itemData.subject,"itemName":"subject","itemLabel":"Betreff","hideLabel":false,"route":_vm.patchRoute,"inputElement":"input","inputType":"text","selectOptions":"","itemValueKey":"","itemTextKey":"","previewValue":_vm.itemData.subject},on:{"emit":function($event){_vm.itemData.subject = $event.value}}}),_c('Patcher',{attrs:{"itemValue":_vm.itemData.message,"itemName":"message","itemLabel":"Inhalt","hideLabel":false,"route":_vm.patchRoute,"inputElement":"textarea-md","inputType":"","selectOptions":"","itemValueKey":"","itemTextKey":"","previewValue":_vm.itemData.message},on:{"emit":function($event){_vm.itemData.message = $event.value}}}),_c('LeadPlaceholderSelectorInfoMails')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }