<template>
  <v-row no-gutters class="my-4">
    <v-col v-for="(tag, index) in tags" :key="index" cols="auto">
      <v-chip
        small
        @click="selectTag(tag)"
        :color="tag.color"
        class="ma-1 white--text"
      >
        {{ tag.info }}
      </v-chip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LeadPlaceholderSelectorInfoMails",

  props: {
    context: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      tags: [
        { name: "%lead-first-name%", color: "green", info: "Vorname" },
        { name: "%lead-last-name%", color: "green", info: "Nachname" },
        { name: "%lead-gender%", color: "green", info: "Geschlecht" },
        { name: "%lead-salutation%", color: "green", info: "Anrede" },
        { name: "%lead-title%", color: "green", info: "Titel" },
        { name: "%lead-email%", color: "green", info: "E-Mail" },
        { name: "%lead-phone%", color: "green", info: "Telefon" },
        { name: "%lead-address%", color: "green", info: "Adresse" },
        {
          name: "%lead-country-name%",
          color: "green",
          info: "Name des Landes",
        },
        { name: "%lead-country-code%", color: "green", info: "Ländercode" },
        { name: "%lead-zip%", color: "green", info: "PLZ" },
        { name: "%lead-city%", color: "green", info: "Stadt" },
        { name: "%company-name%", color: "orange", info: "Firmenname" },
        { name: "%company-address%", color: "orange", info: "Firmenadresse" },
        { name: "%company-zip%", color: "orange", info: "Firmen-PLZ" },
        { name: "%company-city%", color: "orange", info: "Firmenstadt" },
        {
          name: "%company-country-name%",
          color: "orange",
          info: "Name des Landes",
        },
        { name: "%company-country-code%", color: "orange", info: "Ländercode" },
        { name: "%company-phone%", color: "orange", info: "Firmen-Telefon" },
        { name: "%company-email%", color: "orange", info: "Firmen-E-Mail" },
        {
          name: "%company-website-url%",
          color: "orange",
          info: "Firmen-Website URL",
        },
        {
          name: "%company-website-link%",
          color: "orange",
          info: "Firmen-Website Link",
        },
        {
          name: "%company-imprint-url%",
          color: "orange",
          info: "Firmen-Impressum",
        },
        { name: "%company-terms-url%", color: "orange", info: "Firmen-AGB" },
        {
          name: "%company-dataprotection-url%",
          color: "orange",
          info: "Firmen-Datenschutz",
        },
        {
          name: "%lead-campaign-recommendation-url%",
          color: "blue",
          info: "URL zur Weiterempfehlung",
        },
        {
          name: "%lead-campaign-recommendation-link%",
          color: "blue",
          info: "Link zur Weiterempfehlung",
        },
      ],
    };
  },
  methods: {
    selectTag(tag) {
      // copy tag to clipboard
      navigator.clipboard.writeText(tag.name);
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.errorMessage = "";
      this.$root.snackbarGlobal.snackbarText = "Tag " + tag.name + " kopiert!";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 2500;
    },
  },
};
</script>
