<template>
  <div>
    <v-card class="mb-2">
      <v-toolbar color="primary" dark dense>
        <v-toolbar-title>Channel Messages</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <DataTable
          @tableAction="emittedAction"
          v-bind:items="data"
          v-bind:headers="headers"
          :itemActions="{
            edit: true,
            trash: true,
          }"
          :preloader="preloader"
        />
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="detailDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Edit: {{ itemData.title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="detailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <ChannelMessage :itemData="itemData" @updated="getData()" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fixed
          dark
          fab
          bottom
          right
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="createNew"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Neue Message anlegen</span>
    </v-tooltip>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import DataTable from "@/components/ui/DataTable.vue";
import ChannelMessage from "@/components/addons/info_mails_channels/ChannelMessage.vue";
export default {
  name: "ChannelMessages",
  props: ["channelId"],
  components: {
    DataTable,
    ChannelMessage,
  },

  data() {
    return {
      filePatcherKey: 0,
      fetchingData: false,
      data: [],
      editable: true,
      preloader: false,
      detailDialog: false,
      componentKey: 0,
      itemData: [],
      itemIndex: 0,
      headers: [
        { text: "ID", value: "id", width: "80px" },
        { text: "Aktiv", value: "isActive", width: "80px" },
        { text: "Betreff", value: "subject" },
        { text: "Versand Tag", value: "sendDay" },
        { text: "Versand Monat", value: "sendMonth" },
        { text: "Versand Uhrzeit", value: "sendTime" },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },

  async created() {
    this.getData();
  },

  methods: {
    async getData() {
      // get data of the category
      this.preloader = true;
      let response = await this.getRequest(
        `addons/infoMails/channels/${this.channelId}/messages`
      );
      this.preloader = false;
      this.data = response.data.data;
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */
        this.currentItemId = value.itemId;
        this.currentItemIndex = value.itemIndex;
        this.deleteItem();
      } else if (value.action === "editItem") {
        this.componentKey += 1;
        this.itemData = value.item;
        this.itemIndex = value.index;
        this.detailDialog = true;
      }
    },

    async createNew() {
      /* create new entry */
      let response = await this.postRequest(
        `addons/infoMails/channels/${this.channelId}/messages`
      ); // await data from mixin

      let newItem = response.data.data;
      this.data.push(newItem);

      /* start snackbar */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Eine neue Message wurde erfolgreich angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "green";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },

    async deleteItem() {
      const userChoice = await this.$dialog.confirm({
        text: `Soll diese Message wirklich gelöscht werden?`,
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        this.fetchingData = true;
        let response = await this.deleteRequest(
          `addons/infoMails/messages/${this.currentItemId}`
        );
        this.fetchingData = false;

        if (response) {
          this.data.splice(this.currentItemIndex, 1);
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Die Message wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
      }
    },
  },

  mixins: [apiRequest],
};
</script>
