<template>
  <v-card class="mb-2">
    <v-toolbar color="secondary" dark dense>
      <v-toolbar-title>Adresslisten importieren</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form" v-model="isFormValid" v-on:submit.prevent="safeData()">
        <v-row>
          <v-col cols="12">
            <v-file-input
              ref="file"
              v-model="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              v-on:change="handleFileUpload"
              :disabled="progess ? true : false"
              :rules="[rules.required]"
              hint="Bitte laden Sie eine CSV-Datei hoch."
              persistent-hint
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="uploadSafeguarding"
              label="Der Kunde hat schriftlich bestätigt, dass wir die zu importierenden Daten für den vereinbarten Zweck verwenden dürfen."
              :rules="[rules.required]"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-btn
          depressed
          color="primary"
          :disabled="!isFormValid ? true : false"
          :loading="progess"
          v-on:click="submitFile()"
        >
          Upload
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
import store from "@/store";
import axios from "axios";
export default {
  name: "ImportChannelMembers",
  props: ["channelId"],

  data() {
    return {
      isFormValid: false,
      file: null,
      uploadSafeguarding: false,
      progess: false,
      rules: {
        required: (value) => !!value || this.$t("required"),
      },
    };
  },

  methods: {
    submitFile() {
      this.progess = true;
      this.$emit("progress", true);
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("channelId", this.channelId);

      const url = `/addons/infoMails/members/upload`;

      const that = this;

      axios
        .post(url, formData, {
          headers: {
            Token: store.getters["auth/token"],
          },
        })
        .then(function () {
          that.progess = false;
          that.file = null;
          that.uploadSafeguarding = false;

          // reset input fields
          that.$refs.form.resetValidation();

          /* start snackbar */
          that.$root.snackbarGlobal.snackbar = true;
          that.$root.snackbarGlobal.snackbarText =
            "Die Daten wurden erfolgreich importiert.";
          that.$root.snackbarGlobal.errorMessage = "";
          that.$root.snackbarGlobal.snackbarColor = "success";
          that.$root.snackbarGlobal.snackbarTimeout = 10000;
        })
        .catch(function (error) {
          that.$emit("uploaded", error);
          that.progess = false;

          /* start snackbar */
          that.$root.snackbarGlobal.snackbar = true;
          that.$root.snackbarGlobal.snackbarText =
            "Fehler beim Importieren der Daten.";
          that.$root.snackbarGlobal.errorMessage = error;
          that.$root.snackbarGlobal.snackbarColor = "red";
          that.$root.snackbarGlobal.snackbarTimeout = 10000;
        });
    },

    handleFileUpload(file) {
      this.file = file;
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
