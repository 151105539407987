<template>
  <v-container>
    <Patcher
      :itemValue="itemData.isActive"
      itemName="isActive"
      :route="patchRoute"
      inputElement="switch"
      inputType=""
      :previewValue="itemData.isActive"
      itemLabel="Aktiv"
      @emit="itemData.isActive = $event.value"
    ></Patcher>

    <!-- Patcher (sendMonth) Select with Option (months) 1 - 12 -->

    <Patcher
      :itemValue="itemData.sendDay"
      itemName="sendDay"
      itemLabel="Versand Tag"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="select"
      inputType="number"
      :selectOptions="[
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
      ]"
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.sendDay"
      @emit="itemData.sendDay = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.sendMonth"
      itemName="sendMonth"
      itemLabel="Versand Monat"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="select"
      inputType="number"
      :selectOptions="[
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ]"
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.sendMonth"
      @emit="itemData.sendMonth = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.sendTime"
      itemName="sendTime"
      itemLabel="Versand Uhrzeit"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="time"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.sendTime"
      @emit="itemData.sendTime = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.subject"
      itemName="subject"
      itemLabel="Betreff"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="input"
      inputType="text"
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.subject"
      @emit="itemData.subject = $event.value"
    ></Patcher>

    <Patcher
      :itemValue="itemData.message"
      itemName="message"
      itemLabel="Inhalt"
      :hideLabel="false"
      :route="patchRoute"
      inputElement="textarea-md"
      inputType=""
      selectOptions=""
      itemValueKey=""
      itemTextKey=""
      :previewValue="itemData.message"
      @emit="itemData.message = $event.value"
    ></Patcher>

    <LeadPlaceholderSelectorInfoMails />
  </v-container>
</template>

<script>
import Patcher from "@/components/ui/Patcher.vue";
import LeadPlaceholderSelectorInfoMails from "@/components/ui/LeadPlaceholderSelectorInfoMails.vue";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "ChannelMessage",

  components: {
    Patcher,
    LeadPlaceholderSelectorInfoMails,
  },

  props: ["itemData"],

  data() {
    return {
      filePatcherKey: 0,
      patchRoute: `addons/infoMails/messages/${this.itemData.id}`,
      fetchingData: false,
    };
  },

  methods: {
    patched(value) {
      this.itemData.group_id = value;
      this.$emit("updated");
    },
  },

  mixins: [apiRequest, helpers],
};
</script>
